import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Helmet from "react-helmet";
import AniLink from "gatsby-plugin-transition-link/AniLink";

class NotFound extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { data } = this.props;
    const spaces = data.contentfulSpaces;

    return (
      <Layout>
        <Helmet>
          <title>Not Found - Gracehouse Church Logan</title>
          <meta
            property="og:title"
            content="Not Found - Gracehouse Church Logan"
          />
          <meta name="robots" content="noindex"></meta>
          <meta
            name="description"
            content="Sorry, we've been unable to find that page."
          />
          <meta
            property="og:description"
            content="Sorry, we've been unable to find that page."
          />
        </Helmet>
        <section className="thanks-header">
          <div className="container container-sm">
            <h1>
              We can't find that <span>page.</span>
            </h1>
            <p>Sorry, we've been unable to find that page.</p>
            <AniLink className="button" fade to="/">
              Return Home
            </AniLink>
          </div>
        </section>
      </Layout>
    );
  }
}

export default NotFound;

export const pageQuery = graphql`
  query NotFoundQuery {
    site {
      siteMetadata {
        title
      }
    }
    contentfulSpaces {
      description {
        description
      }
      email
      spaceList {
        description
        features
        id
        title
        images {
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }
`;
